@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1734937546419/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1734937546419/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1734937546419/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-alpspix:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-bob:before { content: "\EA06" }
.icon-breite:before { content: "\EA07" }
.icon-car-bold:before { content: "\EA08" }
.icon-car:before { content: "\EA09" }
.icon-cart:before { content: "\EA0A" }
.icon-check:before { content: "\EA0B" }
.icon-close:before { content: "\EA0C" }
.icon-conveyor:before { content: "\EA0D" }
.icon-cross-ski-trail:before { content: "\EA0E" }
.icon-cross:before { content: "\EA0F" }
.icon-diamond:before { content: "\EA10" }
.icon-drag-lift:before { content: "\EA11" }
.icon-dropdown:before { content: "\EA12" }
.icon-elements:before { content: "\EA13" }
.icon-eye:before { content: "\EA14" }
.icon-facebook:before { content: "\EA15" }
.icon-facility:before { content: "\EA16" }
.icon-flaeche:before { content: "\EA17" }
.icon-forest:before { content: "\EA18" }
.icon-gondola-navbar:before { content: "\EA19" }
.icon-gondola:before { content: "\EA1A" }
.icon-halfpipe:before { content: "\EA1B" }
.icon-heart:before { content: "\EA1C" }
.icon-hiking:before { content: "\EA1D" }
.icon-iglu:before { content: "\EA1E" }
.icon-info:before { content: "\EA1F" }
.icon-instagram:before { content: "\EA20" }
.icon-key:before { content: "\EA21" }
.icon-laenge:before { content: "\EA22" }
.icon-language:before { content: "\EA23" }
.icon-linkedin:before { content: "\EA24" }
.icon-location:before { content: "\EA25" }
.icon-logout:before { content: "\EA26" }
.icon-mail:before { content: "\EA27" }
.icon-menue-closed:before { content: "\EA28" }
.icon-menue-open:before { content: "\EA29" }
.icon-minus:before { content: "\EA2A" }
.icon-mountains:before { content: "\EA2B" }
.icon-news:before { content: "\EA2C" }
.icon-opening-hours:before { content: "\EA2D" }
.icon-order-history:before { content: "\EA2E" }
.icon-pen:before { content: "\EA2F" }
.icon-persons:before { content: "\EA30" }
.icon-phone:before { content: "\EA31" }
.icon-photo:before { content: "\EA32" }
.icon-pinterest:before { content: "\EA33" }
.icon-pipe:before { content: "\EA34" }
.icon-play:before { content: "\EA35" }
.icon-profil:before { content: "\EA36" }
.icon-rack-railway:before { content: "\EA37" }
.icon-raum-hoehe:before { content: "\EA38" }
.icon-restaurant-bold:before { content: "\EA39" }
.icon-restaurant:before { content: "\EA3A" }
.icon-rodeln:before { content: "\EA3B" }
.icon-room:before { content: "\EA3C" }
.icon-schlepper:before { content: "\EA3D" }
.icon-search:before { content: "\EA3E" }
.icon-seilbahn-gletscherbahn:before { content: "\EA3F" }
.icon-seilbahn-zugspitze-two:before { content: "\EA40" }
.icon-seilbahn-zugspitze:before { content: "\EA41" }
.icon-seilbahn:before { content: "\EA42" }
.icon-sessellift-6er:before { content: "\EA43" }
.icon-shopping-cart:before { content: "\EA44" }
.icon-sled-steep:before { content: "\EA45" }
.icon-sled:before { content: "\EA46" }
.icon-slope:before { content: "\EA47" }
.icon-snow:before { content: "\EA48" }
.icon-snowpark:before { content: "\EA49" }
.icon-swipe:before { content: "\EA4A" }
.icon-ticket:before { content: "\EA4B" }
.icon-trash:before { content: "\EA4C" }
.icon-tuer-breite:before { content: "\EA4D" }
.icon-tuer-hoehe:before { content: "\EA4E" }
.icon-tunnel:before { content: "\EA4F" }
.icon-twitter:before { content: "\EA50" }
.icon-video:before { content: "\EA51" }
.icon-wanderer-pistenplan:before { content: "\EA52" }
.icon-warning:before { content: "\EA53" }
.icon-water:before { content: "\EA54" }
.icon-weather-1:before { content: "\EA55" }
.icon-weather-10:before { content: "\EA56" }
.icon-weather-11:before { content: "\EA57" }
.icon-weather-12:before { content: "\EA58" }
.icon-weather-13:before { content: "\EA59" }
.icon-weather-14:before { content: "\EA5A" }
.icon-weather-15:before { content: "\EA5B" }
.icon-weather-16:before { content: "\EA5C" }
.icon-weather-17:before { content: "\EA5D" }
.icon-weather-18:before { content: "\EA5E" }
.icon-weather-19:before { content: "\EA5F" }
.icon-weather-2:before { content: "\EA60" }
.icon-weather-20:before { content: "\EA61" }
.icon-weather-21:before { content: "\EA62" }
.icon-weather-22:before { content: "\EA63" }
.icon-weather-23:before { content: "\EA64" }
.icon-weather-24:before { content: "\EA65" }
.icon-weather-25:before { content: "\EA66" }
.icon-weather-26:before { content: "\EA67" }
.icon-weather-27:before { content: "\EA68" }
.icon-weather-28:before { content: "\EA69" }
.icon-weather-29:before { content: "\EA6A" }
.icon-weather-3:before { content: "\EA6B" }
.icon-weather-30:before { content: "\EA6C" }
.icon-weather-31:before { content: "\EA6D" }
.icon-weather-32:before { content: "\EA6E" }
.icon-weather-33:before { content: "\EA6F" }
.icon-weather-34:before { content: "\EA70" }
.icon-weather-35:before { content: "\EA71" }
.icon-weather-4:before { content: "\EA72" }
.icon-weather-5:before { content: "\EA73" }
.icon-weather-6:before { content: "\EA74" }
.icon-weather-7:before { content: "\EA75" }
.icon-weather-8:before { content: "\EA76" }
.icon-weather-9:before { content: "\EA77" }
.icon-weather:before { content: "\EA78" }
.icon-webcam:before { content: "\EA79" }
.icon-whatsapp:before { content: "\EA7A" }
.icon-youtube:before { content: "\EA7B" }
.icon-zahnradbahn:before { content: "\EA7C" }
.icon-zoom:before { content: "\EA7D" }


:root {
--icon-addthis: "\EA01";
    --icon-alpspix: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-bob: "\EA06";
    --icon-breite: "\EA07";
    --icon-car-bold: "\EA08";
    --icon-car: "\EA09";
    --icon-cart: "\EA0A";
    --icon-check: "\EA0B";
    --icon-close: "\EA0C";
    --icon-conveyor: "\EA0D";
    --icon-cross-ski-trail: "\EA0E";
    --icon-cross: "\EA0F";
    --icon-diamond: "\EA10";
    --icon-drag-lift: "\EA11";
    --icon-dropdown: "\EA12";
    --icon-elements: "\EA13";
    --icon-eye: "\EA14";
    --icon-facebook: "\EA15";
    --icon-facility: "\EA16";
    --icon-flaeche: "\EA17";
    --icon-forest: "\EA18";
    --icon-gondola-navbar: "\EA19";
    --icon-gondola: "\EA1A";
    --icon-halfpipe: "\EA1B";
    --icon-heart: "\EA1C";
    --icon-hiking: "\EA1D";
    --icon-iglu: "\EA1E";
    --icon-info: "\EA1F";
    --icon-instagram: "\EA20";
    --icon-key: "\EA21";
    --icon-laenge: "\EA22";
    --icon-language: "\EA23";
    --icon-linkedin: "\EA24";
    --icon-location: "\EA25";
    --icon-logout: "\EA26";
    --icon-mail: "\EA27";
    --icon-menue-closed: "\EA28";
    --icon-menue-open: "\EA29";
    --icon-minus: "\EA2A";
    --icon-mountains: "\EA2B";
    --icon-news: "\EA2C";
    --icon-opening-hours: "\EA2D";
    --icon-order-history: "\EA2E";
    --icon-pen: "\EA2F";
    --icon-persons: "\EA30";
    --icon-phone: "\EA31";
    --icon-photo: "\EA32";
    --icon-pinterest: "\EA33";
    --icon-pipe: "\EA34";
    --icon-play: "\EA35";
    --icon-profil: "\EA36";
    --icon-rack-railway: "\EA37";
    --icon-raum-hoehe: "\EA38";
    --icon-restaurant-bold: "\EA39";
    --icon-restaurant: "\EA3A";
    --icon-rodeln: "\EA3B";
    --icon-room: "\EA3C";
    --icon-schlepper: "\EA3D";
    --icon-search: "\EA3E";
    --icon-seilbahn-gletscherbahn: "\EA3F";
    --icon-seilbahn-zugspitze-two: "\EA40";
    --icon-seilbahn-zugspitze: "\EA41";
    --icon-seilbahn: "\EA42";
    --icon-sessellift-6er: "\EA43";
    --icon-shopping-cart: "\EA44";
    --icon-sled-steep: "\EA45";
    --icon-sled: "\EA46";
    --icon-slope: "\EA47";
    --icon-snow: "\EA48";
    --icon-snowpark: "\EA49";
    --icon-swipe: "\EA4A";
    --icon-ticket: "\EA4B";
    --icon-trash: "\EA4C";
    --icon-tuer-breite: "\EA4D";
    --icon-tuer-hoehe: "\EA4E";
    --icon-tunnel: "\EA4F";
    --icon-twitter: "\EA50";
    --icon-video: "\EA51";
    --icon-wanderer-pistenplan: "\EA52";
    --icon-warning: "\EA53";
    --icon-water: "\EA54";
    --icon-weather-1: "\EA55";
    --icon-weather-10: "\EA56";
    --icon-weather-11: "\EA57";
    --icon-weather-12: "\EA58";
    --icon-weather-13: "\EA59";
    --icon-weather-14: "\EA5A";
    --icon-weather-15: "\EA5B";
    --icon-weather-16: "\EA5C";
    --icon-weather-17: "\EA5D";
    --icon-weather-18: "\EA5E";
    --icon-weather-19: "\EA5F";
    --icon-weather-2: "\EA60";
    --icon-weather-20: "\EA61";
    --icon-weather-21: "\EA62";
    --icon-weather-22: "\EA63";
    --icon-weather-23: "\EA64";
    --icon-weather-24: "\EA65";
    --icon-weather-25: "\EA66";
    --icon-weather-26: "\EA67";
    --icon-weather-27: "\EA68";
    --icon-weather-28: "\EA69";
    --icon-weather-29: "\EA6A";
    --icon-weather-3: "\EA6B";
    --icon-weather-30: "\EA6C";
    --icon-weather-31: "\EA6D";
    --icon-weather-32: "\EA6E";
    --icon-weather-33: "\EA6F";
    --icon-weather-34: "\EA70";
    --icon-weather-35: "\EA71";
    --icon-weather-4: "\EA72";
    --icon-weather-5: "\EA73";
    --icon-weather-6: "\EA74";
    --icon-weather-7: "\EA75";
    --icon-weather-8: "\EA76";
    --icon-weather-9: "\EA77";
    --icon-weather: "\EA78";
    --icon-webcam: "\EA79";
    --icon-whatsapp: "\EA7A";
    --icon-youtube: "\EA7B";
    --icon-zahnradbahn: "\EA7C";
    --icon-zoom: "\EA7D";
    
}